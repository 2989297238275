/*@import url("https://use.typekit.net/gxg4wgh.css");*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Roboto&family=Anton&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500;700&display=swap');


@font-face {
  font-family: "josephsophia";
  src: url("./assets/fonts/Josephsophia/josephsophia-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "FuturaPTBold";
  src: url("./assets/fonts/FuturaPTBold.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "FuturaPTBook";
  src: url("./assets/fonts/FuturaPTBook.otf") format("opentype");
  font-display: swap;
}
/*@font-face {*/
/*  font-family: "FuturaPTDemi";*/
/*  src: url("./assets/fonts/FuturaPTDemi.otf") format("opentype");*/
/*  font-display: swap;*/
/*}*/
@font-face {
  font-family: "FuturaPTMedium";
  src: url("./assets/fonts/FuturaPTMedium.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("./assets/fonts/Metropolis-Regular.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Recoleta Alt";
  src: url("./assets/fonts/Recoleta/recoleta-regulardemo-webfont.woff") format("woff");
  font-display: swap;
}
/*@font-face {*/
/*  font-family: "Anton Regular";*/
/*  src: url("./assets/fonts/Anton-Regular.ttf") format("truetype");*/
/*  font-display: swap;*/
/*}*/
@font-face {
  font-family: "GilroyRegular";
  src: url("./assets/fonts/Gilroy/Gilroy-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "GilroySemiBold";
  src: url("./assets/fonts/Gilroy/Gilroy-SemiBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "GilroyBold";
  src: url("./assets/fonts/Gilroy/Gilroy-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "GilroyMedium";
  src: url("./assets/fonts/Gilroy/Gilroy-Medium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "GilroyBlack";
  src: url("./assets/fonts/Gilroy/Gilroy-Black.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Arial";
  src: url("./assets/fonts/Arial/arial-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Geometr415 Blk BT";
  src: url("./assets/fonts/geometric415blackbt/geometric-415-black-bt-webfont.woff") format("woff");
  font-display: swap;
}
/*@font-face {*/
/*  font-family: "HeyOctober";*/
/*  src: url("./assets/fonts/HeyOctober.ttf") format("truetype");*/
/*  font-display: swap;*/
/*}*/
@font-face {
  font-family: "Helvetica Neue Medium";
  src: url("./assets/fonts/Helvetica-Neue-Font/Helvetica Neue Medium Extended/Helvetica Neue Medium Extended.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("./assets/fonts/Helvetica-Neue-Font/Helvetica Neue Medium Extended/Helvetica Neue Medium Extended.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GeoSlab703 Md BT";
  src: url("./assets/fonts/GeoSlab/geoslab703_md_bt_bold-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir/AvenirLTStd-Book.otf") format("opentype");
  font-display: swap;
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Open Sans,sans-serif,Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
}
#root{
  height: 100%;
}
html, body {
  height: 100%;
}

@media screen and (min-width: 540px){
  html, body {
    min-width: 1260px !important;
    height: 100%;
  }

}
#ct-container{
    z-index: 5000 !important;
}

@media  screen and  (max-width: 768px){
  /*#ct-container .ct-row{*/
  /*  position: fixed !important;*/
  /*  bottom: 10% !important;*/
  /*  !* top: 50%; *!*/
  /*  right: 5% !important;*/
  /*  left: 5% !important;*/
  /*  margin: 0 auto !important;*/
  /*}*/
}


button:disabled {
  background-color: lightgray !important;
  cursor: no-drop !important;
}

._loading_overlay_wrapper{
  min-height: 100vh;
  height: 100% !important;
}
/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*    monospace;*/
/*}*/
/*.zsiq_floatmain{*/
/*  display: none !important;*/
/*}*/
a{
  text-decoration: none;
  color: #000000;
}
